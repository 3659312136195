import { MetaLinks, MetaTagsOptions } from '~/types/meta-tags'

const BASE_URL = 'https://spellcaster.agency'
const SITE_KEYWORDS = 'Design, Communication, Production, Art Direction, Illustration, Product design, Brand Identity, Art Direction, Illustration, Brand Identity'

export const getMetaTags = (options: MetaTagsOptions) => {
    const locale = options.activeLanguage ?? 'uk'
    const url = options.url ?? ''
    const image = `/images/${options.image}`
    const imageAlt = options.imageAlt ?? 'Page head background'
    return [
        { hid: 'title', name: 'title', content: options.title },
        { hid: 'keywords', name: 'keywords', content: SITE_KEYWORDS },
        {
            hid: 'description',
            name: 'description',
            content: options.description
        },
        {
            hid: 'locale',
            name: 'locale',
            content: locale
        }, {
            hid: 'og:locale',
            name: 'og:locale',
            content: locale
        },
        {
            hid: 'type',
            name: 'type',
            content: 'website'
        },
        { hid: 'og:title', property: 'og:title', content: options.title },
        {
            hid: 'og:description',
            property: 'og:description',
            content: options.description
        },
        { hid: 'og:image', property: 'og:image', content: image },
        {
            hid: 'og:image:alt',
            property: 'og:image:alt',
            content: imageAlt
        },
        { hid: 'og:image:width', property: 'og:image:width', content: '138' },
        { hid: 'og:image:height', property: 'og:image:height', content: '37' },
        {
            hid: 'og:url',
            property: 'og:url',
            content: `${BASE_URL}/${url}`
        },
        {
            hid: 'twitter:card',
            name: 'twitter:card',
            content: 'catalon' // ?
        },
        {
            hid: 'twitter:url',
            name: 'twitter:url',
            content: `${BASE_URL}/${url}`
        },
        {
            hid: 'twitter:title',
            name: 'twitter:title',
            content: options.title
        },
        {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: options.description
        },
        {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: image
        }
    ]
}

export const getHtmlAttrs = (options: any) => {
    return { lang: options.lang ?? 'uk' }
}

export const getMetaLinks = (options: MetaLinks) => {
    return [
        { rel: options.rel, hreflang: options.firstHreflang ?? 'uk', href: options.firstHref },
        { rel: options.rel, hreflang: options.secondHreflang ?? 'en', href: options.secondHref },
        { rel: options.mainRel, href: options.mainHref }
    ]
}
